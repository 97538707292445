import { getBackendAmountFromInputValue } from '@/assets/js/SharedFunctions.js';

export default {
    state: {
        takeOverMsg: false,

        firstContext: '',
        secondContext: false,
        thirdContext: false,

        awaitingNumpadInput: false,
        awaitingKeyboardInput: false,

        paymentUnderway: false,
        activePaymentSession: false,
        
        orderLineAwaitingData: false,
        pendingOrderLineData: false,
        awaitingBoxResponse: false,
        awaitingUpdateOrderResponse: false,
        awaitingPaymentConfirm: false,
        awaitingInvoiceConfirm: false,
        preventedByOtherTab: false,
        isProcessingRequest: false, // Refactor to only use one vuex variable?! Possible/reasonable??
        isProcessingGetRegisterRequest: false,
        customisingOrderLineAtIndex: false,

        secondsBeforeSignOut: false,
        timePassed: false,
        
        inputValue: '',
        quantity: false,
        chosenPaymentAmount: false,

        scaleError: false,
        retryingScale: false,

        userShouldRefreshPage: false,
        isOffline: false,
        yesNoQueries: [],
        openCashDrawerQueryIfPaymentSuccessful: false,

        // I'm introducing this array which will hold any orders in the "current order" queue, i.e. they should become
        // the new current when processing of the current current completes. Currently, the array will only be used to hold
        // the single "remainder" order to which we should return after completing the order into which it was split.
        // Therefore, the array will also hold data on the context to which it should return.
        nextOrdersQueue: [],
        orderSaveQueryQueue: [], // I will use this for any queries we must pass through before saving (or assigning to table) the order
        statusMessagesVerboseMode: false,
    },

    getters: {
        // IMPTODO: Refactor everything to somehow involve thirdContext... or find better solution
        contextIs: (state) => (context) => {
            return state.firstContext === context[0] && state.secondContext === context[1];
        },

        contextIsnt: (state) => (context) => {
            return state.firstContext !== context[0] || state.secondContext !== context[1];
        },

        // This looks at all three variables; above TODO suggest rethinking it; maybe make contextIs into this one
        fullContextIs: (state) => (context) => {
            return state.firstContext === context[0] && state.secondContext === context[1] && state.thirdContext === context[2];
        },

        fullContextIsnt: (state) => (context) => {
            return state.firstContext !== context[0] || state.secondContext !== context[1] || state.thirdContext !== context[2];
        },

        firstContextIs: (state) => (firstContext) => {
            return state.firstContext === firstContext;
        },

        firstContextIsnt: (state) => (firstContext) => {
            return state.firstContext !== firstContext;
        },

        secondContextIs: (state) => (secondContext) => {
            return state.secondContext === secondContext;
        },

        secondContextIsnt: (state) => (secondContext) => {
            return state.secondContext !== secondContext;
        },

        thirdContextIs: (state) => (thirdContext) => {
            return state.thirdContext === thirdContext;
        },

        thirdContextIsnt: (state) => (thirdContext) => {
            return state.thirdContext !== thirdContext;
        },

        tableViewActive: (state) => {
            return ['get-table', 'assign-to-table'].includes(state.secondContext);
        },

        // Getters that determine whether UI elements are disabled
        navigationDisabled: (state, getters) => {
            return getters.nonEmptySalesOrder
                || state.paymentUnderway
                || state.awaitingBoxResponse
                || getters.awaitingNumpadInput
                || getters.secondContextIs('zReportAction')
                || getters.secondContextIs('cartItemCustomisation')
                || getters.secondContextIs('selectRefundOrderLines')
                || getters.secondContextIs('redeemGiftCard')
                || getters.hasYesNoQuery
                || state.awaitingUpdateOrderResponse
                || state.isProcessingRequest
                || getters.showUnpaidPopup
                || getters.showZreportPopup
                || getters.showPaymentWarningPopup && getters.company.payment_status === 'warn' 
                || getters.showPaymentWarningPopup && getters.register.company_payment_status === 'warn'
                || getters.showRegisterDisabledPopup
                || getters.returnMoneyShouldBeGiven
                ;
        },

        cartDisabled: (state, getters, rootState) => {
            return (rootState.order.currentOrder && (rootState.order.currentOrder.status !== 'open' || rootState.order.currentOrder.external_order != null))
                || state.paymentUnderway
                || state.awaitingBoxResponse
                || state.orderLineAwaitingData
                || getters.hasYesNoQuery
                || !getters.contextIsOrdinarySale
                || state.awaitingUpdateOrderResponse
                || state.isProcessingRequest
                || getters.showUnpaidPopup
                || getters.showZreportPopup
                || getters.showPaymentWarningPopup && getters.company.payment_status === 'warn' 
                || getters.showPaymentWarningPopup && getters.register.company_payment_status === 'warn'
                || getters.showRegisterDisabledPopup
                || (getters.returnMoneyShouldBeGiven && !getters.hidePayOutToCustomerNotification)
                || getters.secondContextIs('redeemGiftCard')
                ;
                
        },

        everythingDisabled: (state, getters) => {
            return getters.navigationDisabled
                && getters.cartDisabled
                && (state.paymentUnderway || state.awaitingBoxResponse || state.awaitingUpdateOrderResponse || state.secondContext === 'scaleRetryAnswerReceive' 
                || state.pendingOrderLineData !== false || getters.hasYesNoQuery || state.isProcessingRequest || getters.showUnpaidPopup 
                || getters.showZreportPopup 
                || getters.showPaymentWarningPopup && (getters.company.payment_status === 'warn' || getters.register.company_payment_status === 'warn')
                || getters.showRegisterDisabledPopup || getters.secondContextIs('assignNameToOrderQuery') || getters.secondContextIs('confirmPaymentQuery')
                || getters.secondContextIs('convertToInvoiceQuery') || getters.secondContextIs('alsoMarkAsDelivered') || (getters.secondContextIs('redeemGiftCard') && !getters.awaitingInput));
        },

        // Ugh, this whole code needs to be rewritten to use callbacks/promises and proper incapsulation...
        // reintroducing a getter for this as I am trying to write some simpler code
        awaitingNumpadInputData: (state) => {
            return state.awaitingNumpadInput;
        },

        awaitingAnyNumpadInput: (state) =>  {
            return state.awaitingNumpadInput !== false;
        },

        awaitingNumpadInputOfType: (state) => (inputName) =>  {
            if (state.awaitingNumpadInput === inputName) {
                return true;
            }

            if (state.awaitingNumpadInput.type === inputName) {
                return true;
            }

            return false;
        },

        awaitingAnyKeyboardInput: (state) =>  {
            return state.awaitingKeyboardInput !== false;
        },

        awaitingKeyboardInputOfType: (state) => (inputName) =>  {
            return state.awaitingKeyboardInput === inputName;
        },

        // TODO: Revisit
        awaitingInput: (state, getters) => {
            return state.pendingOrderLineData !== false || getters.hasYesNoQuery || state.awaitingNumpadInput || state.awaitingKeyboardInput;
        },

        inputValue: (state) => {
            return state.inputValue;
        },

        quantity: (state) => {
            if (state.quantity !== false) {
                return state.quantity;
            } else if (state.inputValue !== '') {
                return parseInt(state.inputValue);
            } else {
                return false;
            }
        },

        chosenPaymentAmount: (state) => {
            return state.chosenPaymentAmount;
        },

        paymentUnderway: (state) => {
            return state.paymentUnderway;
        },

        activePaymentSession: (state) => {
            return state.activePaymentSession;
        },

        paymentOfTypeIsUnderway: (state) => (paymentType) => {
            return state.paymentUnderway && state.paymentUnderway.uuid === paymentType.uuid;
        },

        pendingOrderLineData: (state) => {
            return state.pendingOrderLineData;
        },

        isRetryingScale: (state) => {
            return state.retryingScale;
        },

        pendingOrderLineUnitNameOrNull: (state) => {
            if (state.pendingOrderLineData.orderLine.sellable.unit == null) {
                return null;
            } else {
                return state.pendingOrderLineData.orderLine.sellable.unit.name;
            }
        },

        encourageRefresh: (state, getters) => {
            return state.userShouldRefreshPage && !getters.navigationDisabled;
        },

        showOfflinePopup: (state) => {
            return state.isOffline;
        },

        hasYesNoQuery: (state) => {
            return state.yesNoQueries.length > 0;
        },

        firstYesNoQuery: (state) => {
            return state.yesNoQueries[0];
        },

        contextIsOrdinarySale: (state) => {
            return state.firstContext === 'sale' && state.secondContext === false;
        },

        neitherInputValueOrQuantitySet: (state) => {
            return state.inputValue === '' && state.quantity === false;
        },

        isProcessingRequest: (state) => {
            return state.isProcessingRequest;
        },

        isProcessingGetRegisterRequest: (state) => {
            return state.isProcessingGetRegisterRequest;
        },

        takeOverMsg: (state) => {
            return state.takeOverMsg;
        },

        secondsBeforeSignOut: (state) => {
            return state.secondsBeforeSignOut;
        },

        timePassed: (state) => {
            return state.timePassed;
        },

        ordersInQueue: (state) => {
            return state.nextOrdersQueue.length > 0;
        },

        customisingOrderLineAtIndex: (state) => {
            return state.customisingOrderLineAtIndex;
        },

        paymentAmount: (state, getters) => {
            if (!getters.awaitingInput && state.inputValue !== '') {
                return getBackendAmountFromInputValue(state.inputValue);
            } else if (getters.chosenPaymentAmount !== false) {
                return getters.chosenPaymentAmount;
            } else if (getters.orderAwaitingCancelAction) {
                return -(getters.orderTotal - getters.orderBalance);
            } else {
                return getters.orderBalance;
            }
        },

        roundedPaymentAmount: (state, getters) => (rounding) => {
            if (rounding === 1) {
                return getters.paymentAmount;
            } else {
                const paymentAmount = getters.paymentAmount;

                let factor = 1;
                let valueToRound = getters.paymentAmount * (1 / rounding);

                if (!getters.returnMoneyShouldBeGiven) {
                    // We ensure e.g. 0.75 is rounded to 0.5
                    factor = paymentAmount > 0 ? 1 : -1.
                    valueToRound = Math.abs(valueToRound);
                } else {
                    // No processing, i.e. the order total is negative, and so is valueToRound, meaning it will be
                    // rounded away from 0
                }

                return factor * Math.round(valueToRound) / (1 / rounding);
            }
        },

        returnAmount: (state, getters) => {
            if (getters.orderAwaitingCancelAction) {
                return -(getters.orderTotal - getters.orderBalance);
            } else {
                return getters.orderBalance;
            }
        },

        awaitingPaymentConfirm: (state) => {
            return state.awaitingPaymentConfirm;
        },

        awaitingInvoiceConfirm: (state) => {
            return state.awaitingInvoiceConfirm;
        },

        isAwaitingUpdateOrderResponse: (state) => {
            return state.awaitingUpdateOrderResponse;
        },

        statusMessagesOrdered: (state, getters) => {
            const rank = {
                danger: 1,
                warning: 2,
                info: 3,
                success: 4,
            }

            return getters.register.status_messages.sort((a, b) => rank[a.type] - rank[b.type]);
        }
    },

    mutations: {
        resetContext(state) {
            state.takeOverMsg = false;
            state.firstContext = '';
            state.secondContext = false;
            state.thirdContext = false;
            state.awaitingNumpadInput = false;
            state.awaitingKeyboardInput = false;
            state.paymentUnderway = false;
            state.activePaymentSession = false;
            state.orderLineAwaitingData = false;
            state.pendingOrderLineData = false;
            state.awaitingBoxResponse = false;
            state.awaitingUpdateOrderResponse = false;
            state.awaitingPaymentConfirm = false;
            state.awaitingInvoiceConfirm = false;
            state.preventedByOtherTab = false;
            state.isProcessingRequest = false;
            state.isProcessingGetRegisterRequest = false;
            state.customisingOrderLineAtIndex = false;
            state.secondsBeforeSignOut = false;
            state.timePassed = false;
            state.inputValue = '';
            state.quantity = false;
            state.chosenPaymentAmount = false;
            state.scaleError = false;
            state.retryingScale = false;
            state.userShouldRefreshPage = false;
            state.isOffline = false;
            state.yesNoQueries = [];
            state.openCashDrawerQueryIfPaymentSuccessful = false;
            state.nextOrdersQueue = [];
            state.orderSaveQueryQueue = [];
            state.statusMessagesVerboseMode = false;
        },
        setContext(state, context) {
            state.firstContext = context[0];
            state.secondContext = context[1];
            state.thirdContext = (context[2] === undefined) ? false : context[2];
        },

        setFirstContext(state, firstContext) {
            state.firstContext = firstContext;
        },

        setSecondContext(state, secondContext) {
            state.secondContext = secondContext;
        },

        setThirdContext(state, thirdContext) {
            state.thirdContext = thirdContext;
        },

        setDefaultContext(state, floorPlanIsDefault) {
            state.firstContext = floorPlanIsDefault ? 'floorPlan' : 'sale';
            state.secondContext = false;
            state.thirdContext = false;
        },

        setPaymentUnderway(state, paymentType) {
            state.paymentUnderway = paymentType;

            if (paymentType.interface.key === 'cash' && paymentType.open_cash_drawer === 'ask') {
                state.openCashDrawerQueryIfPaymentSuccessful = true;
            }
        },

        setPaymentNotUnderway(state) {
            state.paymentUnderway = false;
            state.activePaymentSession = false; // Only in a few instances (session-activated payment types) not false already
            state.openCashDrawerQueryIfPaymentSuccessful = false;
        },

        setActivePaymentSession(state, paymentSession) {
            state.activePaymentSession = paymentSession;
        },

        setAwaitingScaleRetryAnswer(state, code) {
            state.secondContext = 'scaleRetryAnswerReceive';
            state.scaleError = code;
        },

        setProductToReceiveQuantityInputInstead(state) {
            state.pendingOrderLineData.requirements.requiresResultFromScale = false;
            state.pendingOrderLineData.requirements.requiresQuantityInput = true;
        },

        setFinishedReceivingModifierGroupInput(state) {
            state.pendingOrderLineData.requirements.requiresModifierGroupInput = false;
            state.secondContext = false;
            //state.customisingOrderLineAtIndex = false;
            state.thirdContext = false;
        },

        setAwaitingNumpadInput(state, inputName) {
            state.awaitingNumpadInput = inputName;
            //state.secondContext = inputName ? 'awaitingNumpadInput' : false; // TODO: Try to remove
        },

        setAwaitingKeyboardInput(state, inputName) {
            state.awaitingKeyboardInput = inputName;
            //state.secondContext = inputName ? 'awaitingKeyboardInput' : false; // TODO: Try to remove
        },

        setAwaitingWeight(state) {
            state.awaitingBoxResponse = true;
            //state.secondContext = 'scaleRetryAnswerReceive';
        },

        setAwaitingBoxResponse(state, value) {
            state.awaitingBoxResponse = value;
        },

        setRetryingScale(state, value) {
            state.retryingScale = value;

            state.scaleError = false;
        },

        resetScaleError(state) {
            state.scaleError = false;
        },

        setInputValue(state, value) {
            state.inputValue = value;
        },

        resetInputValue(state) {
            state.inputValue = '';
        },

        addToInputValue(state, value) {
            state.inputValue += value;
        },

        setQuantity(state) {
            const maybeInt = parseInt(state.inputValue);
            state.quantity = isNaN(maybeInt) ? false : maybeInt;
        },

        setChosenPaymentAmount(state, value) {
            state.chosenPaymentAmount = value;
        },

        setQuantityIfRelevant(state) {
            if (state.quantity === false) {
                const maybeInt = parseInt(state.inputValue);
                state.quantity = isNaN(maybeInt) ? false : maybeInt;
            }
        },

        resetQuantity(state) {
            state.quantity = false;
        },

        resetDataDerivedFromUserInput(state) {
            state.inputValue = '';
            state.quantity = false;
            state.chosenPaymentAmount = false;
        },

        setPendingOrderLineData(state, pendingOrderLineData) {
            state.pendingOrderLineData = pendingOrderLineData;
        },

        setReceivedWeight(state, weight) {
            state.pendingOrderLineData.orderLine.quantity *= weight;
            state.pendingOrderLineData.requirements.requiresResultFromScale = false;
            state.awaitingBoxResponse = false;
            state.retryingScale = false;
            state.secondContext = false;
        },

        setReceivedQuantity(state) {
            state.pendingOrderLineData.orderLine.quantity *= parseInt(state.inputValue);
            state.pendingOrderLineData.requirements.requiresQuantityInput = false;
            state.secondContext = false;
            state.awaitingNumpadInput = false;
        },

        setReceivedPrice(state) {
            state.pendingOrderLineData.orderLine.price = Math.round(parseFloat(state.inputValue.replace(',', '.')) * 100);
            state.pendingOrderLineData.requirements.requiresPriceInput = false;
            state.secondContext = false;
            state.awaitingNumpadInput = false;
        },

        useMasterOrderLineQuantityInsteadOfReceiving(state) {
            state.pendingOrderLineData.requirements.requiresQuantityInput = false;
        },

        cancelAddProductAwaitingData(state) {
            state.pendingOrderLineData = false;
            state.secondContext = false;
            state.awaitingNumpadInput = false;
            state.quantity = false;
            state.inputValue = '';
        },

        emptyPendingOrderLineSlaves(state) {
            state.pendingOrderLineData.orderLine.slaves = [];
        },

        setUserShouldRefreshPage(state) {
            state.userShouldRefreshPage = true;
        },

        setOffline(state) {
            state.isOffline = true;
        },

        setOnline(state) {
            state.isOffline = false;
        },

        newYesNoQuery(state, value) {
            state.yesNoQueries.push(value);
        },

        finishYesNoQuery(state) {
            state.yesNoQueries.shift();
        },

        setPreventedByOtherTab(state, value) {
            state.preventedByOtherTab = value;
        },

        setAwaitingUpdateOrderResponse(state, value) {
            state.awaitingUpdateOrderResponse = value;
        },

        setIsProcessingRequest(state, value) {
            state.isProcessingRequest = value;
        },

        setIsProcessingGetRegisterRequest(state, value) {
            state.isProcessingGetRegisterRequest = value;
        },


        setTakeOverMsg(state, value) {
            state.takeOverMsg = value;
        },

        setSecondsBeforeSignOut(state, value) {
            state.secondsBeforeSignOut = value;
        },

        setTimePassed(state, value) {
            state.timePassed = value;
        },

        incrementTimePassed(state) {
            state.timePassed += 1;
        },

        pushOrderToQueue(state, data) {
            state.nextOrdersQueue.push(data);
        },

        shiftPendingOrderLineQuantity(state, change) {
            state.pendingOrderLineData.orderLine.quantity += change;
        },

        setCustomisingOrderLineAtIndex(state, value) {
            state.customisingOrderLineAtIndex = value;
        },

        setAwaitingPaymentConfirm(state, value) {
            state.awaitingPaymentConfirm = value;

            // Uhh, probably not good design, but I'll try
            if (value) {
                state.secondContext = 'confirmPaymentQuery';
            } else {
                state.secondContext = false;
            }
        },

        setAwaitingInvoiceConfirm(state, value) {
            state.awaitingPaymentConfirm = value;

            // Uhh, probably not good design, but I'll try
            if (value) {
                state.secondContext = 'convertToInvoiceQuery';
            } else {
                state.secondContext = false;
            }
        },

        setStatusMessagesVerboseMode(state, value) {
            state.statusMessagesVerboseMode = value;
        },
    },

    actions: {
        shiftOrdersQueue ({ commit, state }) {
            if (state.nextOrdersQueue.length) {
                let nextOrderData = state.nextOrdersQueue.shift();

                commit('setPercentageDiscountIndicator', false);
                commit('setCurrentOrder', nextOrderData.order);

                if (nextOrderData.firstContext != undefined) {
                    state.firstContext = nextOrderData.firstContext;
                }

                if (nextOrderData.secondContext != undefined) {
                    state.secondContext = nextOrderData.secondContext;
                }
            }
        },

        finishNumpadInput ({ commit, state }, extraData) {
            if (state.awaitingNumpadInput.callback) {
                state.awaitingNumpadInput.callback(extraData?.pin);
                commit('setAwaitingNumpadInput', false);
            }
        },

        // show modal if there is <vuex-confirmation-modal> embed in template
        showVuexConfirmationModal({ commit }, extraData) {
            commit('setPaymentNotUnderway');
            commit('setAwaitingBoxResponse', true);
            commit('setTakeOverQuery', {
                headerText: extraData.title,
                queryText: [extraData.queryText, extraData.responseMessage],
                showCancel: false,
                confirmClosure: () => {
                    commit('setAwaitingBoxResponse', false);
                }
            });
        },
    },
}