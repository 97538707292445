export default {
    state: {
        showPaymentWarningPopup: false,
        paymentWarningIntervalId: false,
        paymentWarningPopupShownAt: false,
    },

    getters: {
        // TODO: would be great to also check company payment status here.
        showPaymentWarningPopup: (state) => {
            return state.showPaymentWarningPopup;
        },
    },

    mutations: {
        setPaymentWarningIntervalId(state, value) {
            state.paymentWarningIntervalId = value;
        },

        setPaymentWarningPopupShownAt(state, value) {
            state.paymentWarningPopupShownAt = value;
        },

        setShowPaymentWarningPopup (state, value) {
            state.showPaymentWarningPopup = value;
        },
    },

    actions: {
        startPeriodicPaymentWarningChecksRefresh({ commit, state }) {
            state.paymentWarningIntervalId = setInterval(() => {
                const now = Date.now();

                if (!state.paymentWarningPopupShownAt) {
                    commit('setShowPaymentWarningPopup', true);
                }
                
                if (now - state.paymentWarningPopupShownAt > 60 * 60 * 1000) {
                    commit('setShowPaymentWarningPopup', true);
                }

            }, 5 * 1000);
        },

        clearPaymentWarningInterval({state, commit}) {
            clearInterval(state.paymentWarningIntervalId);
            commit('setPaymentWarningIntervalId', false);
        },

        cleanPaymentWarningTimerForOneHour({commit}) {
            commit('setShowPaymentWarningPopup', false);
            commit('setPaymentWarningPopupShownAt', Date.now());
        },
    },
}