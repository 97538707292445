import Vue from "vue";

const getDefaultState = () => {
    return {
        list: [],
        selectedRow: false,
        previousBookingUuid: false,

        refreshInterval: false,
        isProcessingFetchRequest: false,
        hourShift: false,
    }
}

export default {
    state: getDefaultState,

    getters: {
        bookings: (state) => {
            return state.list;
        },

        hourShift: (state) => {
            return state.hourShift;
        },
        
        currentBooking: (state) => {
            return (state.selectedRow !== false) ? state.list[state.selectedRow] : false;
        },

        currentBookingUuid: (state) => {
            //return (state.selectedRow !== false) ? state.list[state.selectedRow]['uuid'] : false;
            return state.list?.[state.selectedRow]?.['uuid'] ?? false;
        },

        isProcessingGetBookingsRequest: (state) => {
            return state.isProcessingFetchRequest;
        },
    },

    mutations: {
        setIndexOfCurrentBooking (state, index) {
            state.selectedRow = index;
        },

        setBookings (state, bookings) {
            state.list = bookings;
        },

        setHourShift (state, hourShift) {
            state.hourShift = hourShift;
        },

        setIsProcessingGetBookingsRequest (state, value) {
            state.isProcessingFetchRequest = value;
        },

        setCurrentBooking(state, booking) {
            state.currentBooking = booking;
        },

    },

    actions: {
        getBookings({ commit, getters }) {
            return axios.get('bookings', { params: getters.bookingsFetchParams });
        },
    },
}