// Pass a company UUID or null to set the axios company UUID default, depending on whether we are in a
// company-contingent section of the site or not
function setAxiosCompany(value) {
    if (!axios.defaults.params) {
        axios.defaults.params = {};
    }

    if (value) {
        axios.defaults.headers.common['Company-Uuid'] = value;
        axios.defaults.params['company_uuid'] = value;
    } else {
        if (axios.defaults?.params?.['company_uuid']) {
            delete axios.defaults.params['company_uuid'];
        }

        if (axios.defaults?.headers?.common?.['Company-Uuid']) {
            delete axios.defaults.headers.common['Company-Uuid'];
        }
    }

    axios.defaults.params['VUE_APP_VERSION_KEY'] = process.env.VUE_APP_VERSION_KEY;
    axios.defaults.params['VUE_APP_VERSION_BUILD'] = process.env.VUE_APP_VERSION_BUILD;
}

function getBackendAmountFromInputValue(inputValue) {
    return Math.round(parseFloat(inputValue.replace(',', '.')) * 100);
}

export { setAxiosCompany, getBackendAmountFromInputValue };